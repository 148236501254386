import PropTypes from 'prop-types';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    let isLoggedIn = false;

    const token = localStorage.getItem('patient-menagment-token');

    if (token) {
        const decoded = jwtDecode(token);
        const expAt = new Date(0);
        expAt.setUTCSeconds(decoded.exp);

        if (new Date() < expAt) {
            isLoggedIn = true;
        }
    }

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
