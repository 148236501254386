import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    variant: 'default',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setSnackbar(state, action) {
            const {
                open = initialState.open,
                message = initialState.message,
                anchorOrigin = initialState.anchorOrigin,
                variant = initialState.variant,
                alertSeverity = initialState.alertSeverity,
                transition = initialState.transition,
                close = initialState.close,
                actionButton = initialState.actionButton
            } = action.payload;

            return {
                ...state,
                action: !state.action,
                open,
                message,
                anchorOrigin,
                variant,
                alertSeverity,
                transition,
                close: close === false ? close : initialState.close,
                actionButton
            };
        }
    }
});

export const { setSnackbar } = snackbarSlice.actions;
export const selectSnackbar = (state) => state.snackbar;
export default snackbarSlice.reducer;
