import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const filesSlice = createSlice({
    name: 'files',
    initialState: {
        getAll: { ...initialState },
        create: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        createFileStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createFileSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createFileFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createReportReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllFilesStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllFilesSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllFilesFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllFilesReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        deleteFileStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteFileSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteFileFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    createFileStart,
    createFileSuccess,
    createFileFail,
    createFileReset,
    getAllFilesStart,
    getAllFilesSuccess,
    getAllFilesFail,
    getAllFilesReset,
    deleteFileSuccess,
    deleteFileFail
} = filesSlice.actions;

export const createFile = (payload, options) => async (dispatch) => {
    const { recall } = options;
    try {
        const { id, file } = payload;
        const response = await APIClient.post(`/files/${id}`, file);
        if (response && response.data && response.data.success) {
            dispatch(createFileSuccess(response.data));
            if (recall) recall();
            dispatch(addNotification({ severity: 'success', message: 'The file uploaded successfully!' }));
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createFileFail);
    }
};

export const deleteFile = (payload, options) => async (dispatch) => {
    const { recall } = options;
    try {
        const { id } = payload;
        const response = await APIClient.delete(`/files/${id}`);
        if (response && response.data && response.data.success) {
            dispatch(deleteFileSuccess(response.data));
            if (recall) recall();
            dispatch(addNotification({ severity: 'success', message: 'The file deleted successfully!' }));
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteFileFail);
    }
};

export const getAllFiles = (payload, options) => async (dispatch) => {
    try {
        dispatch(getAllFilesStart());
        const { patientId } = payload;
        const { limit, page } = options;
        const response = await APIClient.get(`/files/patient/${patientId}`, {
            params: { limit, page }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllFilesSuccess(response.data));
        } else {
            dispatch(getAllFilesFail('Failed to get all patients!'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllFilesFail);
    }
};

export default filesSlice.reducer;
