import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const PatientsPage = Loadable(lazy(() => import('views/patients')));
const PatientsProfilePage = Loadable(lazy(() => import('views/patients/PatientProfile')));
const CalendarPage = Loadable(lazy(() => import('views/calendar')));
const AppointmentPage = Loadable(lazy(() => import('views/appointments')));
const AddPatientPage = Loadable(lazy(() => import('views/patients/AddPatient')));
const DoctorsPage = Loadable(lazy(() => import('views/doctors')));
const DoctorProfilePage = Loadable(lazy(() => import('views/doctors/DoctorProfile')));

const MainRoutes = {
    path: '/dashboard',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/dashboard/patients',
            element: <PatientsPage />
        },
        {
            path: '/dashboard/patient-profile/:id',
            element: <PatientsProfilePage />
        },
        {
            path: '/dashboard/calendar',
            element: <CalendarPage />
        },
        {
            path: '/dashboard/appointments',
            element: <AppointmentPage />
        },
        {
            path: '/dashboard/patients/add-patient',
            element: <AddPatientPage />
        },
        {
            path: '/dashboard/doctors',
            element: <DoctorsPage />
        },
        {
            path: '/dashboard/doctor-profile/:id',
            element: <DoctorProfilePage />
        }
    ]
};

export default MainRoutes;
