import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import jwtDecode from 'jwt-decode';

const PageTitleComponent = ({ icon, navigation, title, ...others }) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const { id } = useParams();
    const token = localStorage.getItem('patient-menagment-token');
    let decoded;
    let role;

    if (token) {
        decoded = jwtDecode(token);
        role = decoded.role;
    }

    const iconStyle = {
        marginLeft: matchDownXs ? 0 : 20,
        fontSize: matchDownXs ? '22px' : '30px',
        color: theme.palette.grey[800]
    };

    useEffect(() => {
        const findBreadcrumbs = (items) => {
            items.forEach((item) => {
                if ((item.url && item.url === location.pathname) || (item.id && `/${item.id}/${id}` === location.pathname)) {
                    setBreadcrumbs([item]);
                    return;
                }
                if (item.children) {
                    findBreadcrumbs(item.children);
                }
            });
        };

        Object.keys(navigation).forEach((key) => {
            if (role === 'SUPERADMIN') findBreadcrumbs(navigation.superadmin);
            if (role === 'ADMIN') findBreadcrumbs(navigation.admin);
            if (role === 'DOCTOR') findBreadcrumbs(navigation.doctor);
        });
    }, [location.pathname, navigation]);

    let breadcrumbContent = <Typography />;
    let itemTitle = '';
    let ItemIcon;

    if (breadcrumbs.length > 0) {
        const item = breadcrumbs[0];
        itemTitle = item.title;
        ItemIcon = item.icon ? item.icon : null;

        if (item.breadcrumbs !== false) {
            breadcrumbContent = (
                <Grid container justifyContent="center" alignItems="center">
                    {title && item.icon && (
                        <Grid container alignItems="center">
                            <ItemIcon style={iconStyle} />
                            <Typography
                                variant="h2"
                                sx={{ color: theme.palette.grey[800], ml: 1, fontSize: matchDownXs ? '13px' : '20px' }}
                            >
                                {item.title}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            );
        }
    }

    return breadcrumbContent;
};

export default PageTitleComponent;
