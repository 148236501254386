import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchValue: ''
};

const searchBarSlice = createSlice({
    name: 'searchBar',
    initialState,
    reducers: {
        updateSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        resetSearchValue: (state) => {
            state.searchValue = initialState.searchValue;
        }
    }
});

export const { updateSearchValue, resetSearchValue } = searchBarSlice.actions;
export default searchBarSlice.reducer;
