import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import jwtDecode from 'jwt-decode';

const MenuList = () => {
    const token = localStorage.getItem('patient-menagment-token');

    let decoded;
    let role;

    if (token) {
        decoded = jwtDecode(token);
        role = decoded.role;
    }

    let navItems;
    if (role === 'ADMIN') {
        navItems = menuItem.admin.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    }
    if (role === 'SUPERADMIN') {
        navItems = menuItem.superadmin.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    }
    if (role === 'DOCTOR') {
        navItems = menuItem.doctor.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    }

    return <>{navItems}</>;
};

export default MenuList;
