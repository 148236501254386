// const URL = 'http://localhost:5000/api/';

// const URL = 'https://medica.appbites.dev/api/';
const URL = 'https://medicalabs.net/api/';

const Config = {
    TOKEN: localStorage.getItem('patient-menagment-token'),
    BASE_URL: URL
};

export default Config;
