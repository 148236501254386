import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import DashboardDoctorPage from 'views/dashboard/DoctorDashboard';

const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const PatientsPage = Loadable(lazy(() => import('views/patients')));
const PatientsProfilePage = Loadable(lazy(() => import('views/patients/PatientProfile')));
const CalendarPage = Loadable(lazy(() => import('views/calendar')));
const AppointmentPage = Loadable(lazy(() => import('views/appointments')));
const AddPatientPage = Loadable(lazy(() => import('views/patients/AddPatient')));
const DoctorsPage = Loadable(lazy(() => import('views/doctors')));
const DoctorProfilePage = Loadable(lazy(() => import('views/doctors/DoctorProfile')));
const AppointmentsDetailsPage = Loadable(lazy(() => import('views/appointments/AppointmentsDetails')));
const MyProfile = Loadable(lazy(() => import('views/me/Profile')));
const EditPatientPage = Loadable(lazy(() => import('views/patients/EditPatient')));

const DoctorRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDoctorPage />
        },
        {
            path: '/patients',
            element: <PatientsPage />
        },
        {
            path: '/patient/profile/:id',
            element: <PatientsProfilePage />
        },
        {
            path: '/patient/create',
            element: <AddPatientPage />
        },
        {
            path: '/patient/edit/:id',
            element: <EditPatientPage />
        },
        {
            path: '/calendar',
            element: <CalendarPage />
        },
        {
            path: '/appointments',
            element: <AppointmentPage />
        },
        {
            path: '/appointment/profile/:id',
            element: <AppointmentsDetailsPage />
        },
        {
            path: 'patients/add-patient',
            element: <AddPatientPage />
        },
        {
            path: '/doctors',
            element: <DoctorsPage />
        },
        {
            path: '/doctor-profile/:id',
            element: <DoctorProfilePage />
        },
        {
            path: '/profile/:id',
            element: <MyProfile />
        }
    ]
};

export default DoctorRoutes;
