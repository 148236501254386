import { useTheme } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import MyCalendar from 'components/cards/AppointmentTimeline';
import DoctorAnalyticsCard from 'components/cards/DoctorAnalyticsCard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RecentPatientTable from 'components/tables/RecentPatientTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAppointmentsOfDoctor, getAllPatientsOfDoctor } from 'store/dashboard';
import { useEffect, useState } from 'react';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { getAllPatients } from 'store/patients';
import moment from 'moment';

const DashboardDoctorPage = () => {
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const currentUser = useSelector((state) => state.me.getMe);
    const patientsStatsResponse = useSelector((state) => state.dashboard.getPatientsOfDoctor);
    const appointmentsStatsResponse = useSelector((state) => state.dashboard.getAppointmentsOfDoctor);
    const patientsResponse = useSelector((state) => state.patients.getAll);
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllPatientsOfDoctor({ date: new Date() }));
        dispatch(getAllAppointmentsOfDoctor({ date: new Date() }));
    }, [dispatch]);

    useEffect(() => {
        if (currentUser?.data?.doctor?.id) {
            dispatch(getAllPatients({ doctorId: currentUser?.data?.doctor?.id, createdAt: 'asc' }));
        }
    }, [dispatch, currentUser?.data?.doctor?.id]);

    useEffect(() => {
        if (patientsResponse) {
            switch (true) {
                case patientsResponse.loading:
                    setLoading(true);
                    break;
                case patientsResponse.success: {
                    const modifiedData = patientsResponse?.data?.data.docs.map((patient) => ({
                        id: patient?.id,
                        personalnumber: patient?.personalInfo?.personalNumber,
                        name: `${patient?.user?.firstName} ${patient?.user?.lastName}`,
                        email: patient?.user?.email,
                        date: moment(patient?.personalInfo?.birthDate).format('DD/MM/YYYY'),
                        gender: `${
                            patient?.personalInfo?.gender?.charAt(0)?.toUpperCase() + patient?.personalInfo?.gender.slice(1).toLowerCase()
                        }`,
                        phone: patient?.phoneNumber
                    }));
                    setData(modifiedData);
                    setLoading(false);
                    break;
                }
                case patientsResponse.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [patientsResponse]);

    return (
        <Grid lg={12} container item xs={12} spacing={matchDownXs ? 0 : 2}>
            <Grid container alignItems="center" ml={2} mt={1}>
                <Typography variant="subtitle1" sx={{ fontSize: '20px' }} flexDirection="row">
                    Welcome,&nbsp;
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '20px' }}>
                    {'  '}
                    Dr. {`${currentUser?.data?.firstName} ${currentUser?.data?.firstName}`}
                </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
                <Grid lg={12} container item mt={1}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <DoctorAnalyticsCard title="All Pacients" number={patientsStatsResponse?.data?.data?.today} description="Today" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <DoctorAnalyticsCard
                            title="All Appointments"
                            number={appointmentsStatsResponse?.data?.data?.today}
                            description="Today"
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <DoctorAnalyticsCard
                            title="Pacients Per Month"
                            number={patientsStatsResponse?.data?.data?.thisMonth}
                            description="This month"
                            percentage={`${patientsStatsResponse?.data?.data?.growthPercentage}%`}
                            icon={patientsStatsResponse?.data?.data?.growth ? <TrendingUpIcon /> : <TrendingDownIcon />}
                            percentageBackgroundColor={theme.palette.light.pink + 20}
                            textColor={theme.palette.light.pink}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <DoctorAnalyticsCard
                            title="Appointments Per Month"
                            number={appointmentsStatsResponse?.data?.data?.thisMonth}
                            description="This month"
                            percentage={`${appointmentsStatsResponse?.data?.data?.growthPercentage}%`}
                            icon={appointmentsStatsResponse?.data?.data?.growth ? <TrendingUpIcon /> : <TrendingDownIcon />}
                            percentageBackgroundColor={theme.palette.light.yellowlight + 70}
                            textColor={theme.palette.warning.dark}
                        />
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} mb={3} xs={12}>
                    <RecentPatientTable data={data} />
                </Grid>
            </Grid>
            <Grid lg={4} md={6} sm={6} xs={12} item mt={2}>
                <Grid>
                    <MyCalendar />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardDoctorPage;
