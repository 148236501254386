import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DashboardSuperAdminPage = Loadable(lazy(() => import('views/dashboard/SuperAdminDashboard')));
const AdminsPage = Loadable(lazy(() => import('views/admins')));
const AddAdminPage = Loadable(lazy(() => import('views/admins/AddAdmin')));
const EditAdminPage = Loadable(lazy(() => import('views/admins/EditAdmin')));
const AdminProfilePage = Loadable(lazy(() => import('views/admins/AdminProfile')));
const ClinicsPage = Loadable(lazy(() => import('views/clinics')));
const AddClinicPage = Loadable(lazy(() => import('views/clinics/AddClinic')));
const EditClinicPage = Loadable(lazy(() => import('views/clinics/EditClinic')));
const ClinicProfilePage = Loadable(lazy(() => import('views/clinics/ClinicProfile')));
const MyProfile = Loadable(lazy(() => import('views/me/Profile')));

const SuperAdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/admins',
            element: <AdminsPage />
        },
        {
            path: '/admin/create',
            element: <AddAdminPage />
        },
        {
            path: '/admin/edit/:id',
            element: <EditAdminPage />
        },
        {
            path: '/admin/profile/:id',
            element: <AdminProfilePage />
        },
        {
            path: '/clinics',
            element: <ClinicsPage />
        },
        {
            path: '/clinic/create',
            element: <AddClinicPage />
        },
        {
            path: '/clinic/edit/:id',
            element: <EditClinicPage />
        },
        {
            path: '/clinic/profile/:id',
            element: <ClinicProfilePage />
        },
        {
            path: '/profile/:id',
            element: <MyProfile />
        }
    ]
};

export default SuperAdminRoutes;
