import axios from 'axios';
import config from './config';
import jwtDecode from 'jwt-decode';

const APIClient = axios.create({
    baseURL: config.BASE_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

APIClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('patient-menagment-token');
        if (token) {
            const decoded = jwtDecode(token);

            const expAt = new Date(0);
            expAt.setUTCMilliseconds(decoded.exp * 1000);

            if (new Date(Date.now()) > new Date(expAt)) {
                localStorage.clear();
                window.location.replace('/login');
            } else {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (err) => Promise.reject(err)
);

APIClient.interceptors.response.use(
    async (response) => response,
    (error) => {
        if (error && error.response && error.response.data && error.response.data.token_expired === true) {
            localStorage.clear();
            window.location.replace('/login');
        }
        return Promise.reject(error);
    }
);

export default APIClient;
