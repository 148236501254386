import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const patientSlice = createSlice({
    name: 'patients',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState },
        createFile: { ...initialState },
        deleteFile: { ...initialState }
    },
    reducers: {
        createPatientStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createPatientSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createPatientFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createPatientReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllPatientsStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllPatientsSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllPatientsFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllPatientsReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOnePatientStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOnePatientSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOnePatientFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOnePatientReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updatePatientStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updatePatientSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updatePatientFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updatePatientReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deletePatientStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deletePatientSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deletePatientFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        },
        createFileStart(state) {
            state.createFile.data = null;
            state.createFile.error = false;
            state.createFile.errorMessage = null;
            state.createFile.loading = true;
            state.createFile.success = false;
        },
        createFileSuccess(state, action) {
            state.createFile.data = action.payload;
            state.createFile.error = false;
            state.createFile.errorMessage = null;
            state.createFile.loading = false;
            state.createFile.success = true;
        },
        createFileFail(state, action) {
            state.createFile.data = null;
            state.createFile.errorMessage = action.payload;
            state.createFile.error = true;
            state.createFile.loading = false;
            state.createFile.success = false;
        },
        createFileReset(state) {
            state.createFile.data = null;
            state.createFile.error = false;
            state.createFile.errorMessage = null;
            state.createFile.loading = false;
            state.createFile.success = false;
        },
        deleteFileStart(state) {
            state.deleteFile.data = null;
            state.deleteFile.error = false;
            state.deleteFile.errorMessage = null;
            state.deleteFile.loading = true;
            state.deleteFile.success = false;
        },
        deleteFileSuccess(state, action) {
            state.deleteFile.data = action.payload;
            state.deleteFile.error = false;
            state.deleteFile.errorMessage = null;
            state.deleteFile.loading = false;
            state.deleteFile.success = true;
        },
        deleteFileFail(state, action) {
            state.deleteFile.data = null;
            state.deleteFile.errorMessage = action.payload;
            state.deleteFile.error = true;
            state.deleteFile.loading = false;
            state.deleteFile.success = false;
        }
    }
});

export const {
    createPatientStart,
    createPatientSuccess,
    createPatientFail,
    createPatientReset,
    getAllPatientsStart,
    getAllPatientsSuccess,
    getAllPatientsFail,
    getAllPatientsReset,
    updatePatientStart,
    updatePatientSuccess,
    updatePatientFail,
    updatePatientReset,
    deletePatientStart,
    deletePatientSuccess,
    deletePatientFail,
    getOnePatientFail,
    getOnePatientStart,
    getOnePatientSuccess,
    getOneUserSuccess,
    createFileFail,
    createFileReset,
    createFileStart,
    createFileSuccess,
    deleteFileStart,
    deleteFileSuccess,
    deleteFileFail
} = patientSlice.actions;

export const createPatient = ({ patientData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(createPatientStart());
        const response = await APIClient.post('/patients/create', patientData);
        if (response && response.data && response.data.success) {
            dispatch(createPatientSuccess(response.data));
            if (recall) recall();
            navigate(pathname);
            dispatch(addNotification({ severity: 'success', message: 'The patient created successfully!' }));
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createPatientFail);
    }
};

export const createFileOfPatient = (payload, options) => async (dispatch) => {
    const { recall } = options;
    try {
        const { id, file } = payload;
        const response = await APIClient.post(`/patients/upload/${id}`, file);
        if (response && response.data && response.data.success) {
            dispatch(createFileSuccess(response.data));
            if (recall) recall();
            dispatch(addNotification({ severity: 'success', message: 'The file uploaded successfully!' }));
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createFileFail);
    }
};

export const deleteFileOfPatient = (payload, options) => async (dispatch) => {
    const { recall } = options;
    try {
        dispatch(deleteFileStart());
        const { id } = payload;
        const response = await APIClient.delete(`/patients/file/${id}`);
        if (response && response.data && response.data.success) {
            dispatch(deleteFileSuccess(response.data));
            if (recall) recall();
            dispatch(addNotification({ severity: 'success', message: 'The file deleted successfully!' }));
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteFileFail);
    }
};

export const getAllPatients = (data) => async (dispatch) => {
    try {
        dispatch(getAllPatientsStart());
        const response = await APIClient.get('/patients', {
            params: {
                limit: data.limit,
                page: data.page,
                search: data.search,
                doctorId: data.doctorId,
                createdAt: data.createdAt
            }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllPatientsSuccess(response.data));
        } else {
            dispatch(getAllPatientsFail('Failed to get all patients!'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllPatientsFail);
    }
};

export const getOnePatient = (payload, options) => async (dispatch) => {
    try {
        dispatch(getOnePatientStart());
        const { id } = payload;
        const { limit, page } = options;
        const response = await APIClient.get(`/patients/${id}`, {
            params: { limit, page }
        });
        if (response && response.data && response.data.success) {
            dispatch(getOnePatientSuccess(response.data));
        } else {
            dispatch(getOnePatientFail('Failed to get one patient!'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOnePatientFail);
    }
};

export const updatePatient = ({ patientId, patientData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updatePatientStart());
        const response = await APIClient.patch(`/patients/${patientId}`, patientData);
        if (response && response.data) {
            dispatch(updatePatientSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The patient updated successfully!' }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(updatePatientFail('Failed to update patient!'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updatePatientFail);
    }
};

export const deletePatient = (patientId, recall) => async (dispatch) => {
    try {
        dispatch(deletePatientStart());
        const response = await APIClient.patch(`/patients/${patientId}`, { isActive: false });
        if (response.data && response.data && response.data.success) {
            dispatch(deletePatientSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The patient deleted successfully!' }));
            if (recall) recall();
        } else {
            dispatch(deletePatientFail('Failed to delete patient!'));
            dispatch(addNotification({ severity: 'error', message: 'Failed to delete the patient!' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deletePatientFail);
    }
};

export default patientSlice.reducer;
