import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
    name: 'language',
    initialState: {
        language: 'en'
    },
    reducers: {
        changeLanguageState(state, action) {
            state.language = action.payload;
        }
    }
});

export const { changeLanguageState } = languageSlice.actions;

export const changeLanguage = (language) => (dispatch) => {
    dispatch(changeLanguageState(language));
};

export default languageSlice.reducer;
