import { createSlice } from '@reduxjs/toolkit';
import APIClient from 'services/ApiClient';
import { getAllAppointmentsStart } from 'store/calendar';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        getTodayAppointments: { ...initialState },
        getTodayPatients: { ...initialState },
        getPatientsByMonth: { ...initialState },
        getPatientsByAge: { ...initialState },
        getPatientsOfDoctor: { ...initialState },
        getAppointmentsOfDoctor: { ...initialState },
        getUpcommingAppointments: { ...initialState }
    },
    reducers: {
        getAllTodayAppointmentsStart(state) {
            state.getTodayAppointments.data = null;
            state.getTodayAppointments.error = false;
            state.getTodayAppointments.errorMessage = null;
            state.getTodayAppointments.loading = true;
            state.getTodayAppointments.success = false;
        },
        getAllTodayAppointmentsSuccess(state, action) {
            state.getTodayAppointments.data = action.payload;
            state.getTodayAppointments.error = false;
            state.getTodayAppointments.errorMessage = null;
            state.getTodayAppointments.loading = false;
            state.getTodayAppointments.success = true;
        },
        getAllTodayAppointmentsFail(state, action) {
            state.getTodayAppointments.data = null;
            state.getTodayAppointments.errorMessage = action.payload;
            state.getTodayAppointments.error = true;
            state.getTodayAppointments.loading = false;
            state.getTodayAppointments.success = false;
        },
        getAllAppointmentsReset(state) {
            state.getTodayAppointments.data = null;
            state.getTodayAppointments.error = false;
            state.getTodayAppointments.errorMessage = null;
            state.getTodayAppointments.loading = false;
            state.getTodayAppointments.success = false;
        },
        getAllTodayPatientsStart(state) {
            state.getTodayPatients.data = null;
            state.getTodayPatients.error = false;
            state.getTodayPatients.errorMessage = null;
            state.getTodayPatients.loading = true;
            state.getTodayPatients.success = false;
        },
        getAllTodayPatientsSuccess(state, action) {
            state.getTodayPatients.data = action.payload;
            state.getTodayPatients.error = false;
            state.getTodayPatients.errorMessage = null;
            state.getTodayPatients.loading = false;
            state.getTodayPatients.success = true;
        },
        getAllTodayPatientsFail(state, action) {
            state.getTodayPatients.data = null;
            state.getTodayPatients.errorMessage = action.payload;
            state.getTodayPatients.error = true;
            state.getTodayPatients.loading = false;
            state.getTodayPatients.success = false;
        },
        getAllTodayPatientsReset(state) {
            state.getTodayPatients.data = null;
            state.getTodayPatients.error = false;
            state.getTodayPatients.errorMessage = null;
            state.getTodayPatients.loading = false;
            state.getTodayPatients.success = false;
        },
        getAllPatientsByMonthStart(state) {
            state.getPatientsByMonth.data = null;
            state.getPatientsByMonth.error = false;
            state.getPatientsByMonth.errorMessage = null;
            state.getPatientsByMonth.loading = true;
            state.getPatientsByMonth.success = false;
        },
        getAllPatientsByMonthSuccess(state, action) {
            state.getPatientsByMonth.data = action.payload;
            state.getPatientsByMonth.error = false;
            state.getPatientsByMonth.errorMessage = null;
            state.getPatientsByMonth.loading = false;
            state.getPatientsByMonth.success = true;
        },
        getAllPatientsByMonthFail(state, action) {
            state.getPatientsByMonth.data = null;
            state.getPatientsByMonth.errorMessage = action.payload;
            state.getPatientsByMonth.error = true;
            state.getPatientsByMonth.loading = false;
            state.getPatientsByMonth.success = false;
        },
        getAllPatientsByMonthReset(state) {
            state.getPatientsByMonth.data = null;
            state.getPatientsByMonth.error = false;
            state.getPatientsByMonth.errorMessage = null;
            state.getPatientsByMonth.loading = false;
            state.getPatientsByMonth.success = false;
        },
        getAllPatientsByAgeStart(state) {
            state.getPatientsByAge.data = null;
            state.getPatientsByAge.error = false;
            state.getPatientsByAge.errorMessage = null;
            state.getPatientsByAge.loading = true;
            state.getPatientsByAge.success = false;
        },
        getAllPatientsByAgeSuccess(state, action) {
            state.getPatientsByAge.data = action.payload;
            state.getPatientsByAge.error = false;
            state.getPatientsByAge.errorMessage = null;
            state.getPatientsByAge.loading = false;
            state.getPatientsByAge.success = true;
        },
        getAllPatientsByAgeFail(state, action) {
            state.getPatientsByAge.data = null;
            state.getPatientsByAge.errorMessage = action.payload;
            state.getPatientsByAge.error = true;
            state.getPatientsByAge.loading = false;
            state.getPatientsByAge.success = false;
        },
        getAllPatientsByAgeReset(state) {
            state.getPatientsByAge.data = null;
            state.getPatientsByAge.error = false;
            state.getPatientsByAge.errorMessage = null;
            state.getPatientsByAge.loading = false;
            state.getPatientsByAge.success = false;
        },
        getAllPatientsOfDoctorStart(state) {
            state.getPatientsOfDoctor.data = null;
            state.getPatientsOfDoctor.error = false;
            state.getPatientsOfDoctor.errorMessage = null;
            state.getPatientsOfDoctor.loading = true;
            state.getPatientsOfDoctor.success = false;
        },
        getAllPatientsOfDoctorSuccess(state, action) {
            state.getPatientsOfDoctor.data = action.payload;
            state.getPatientsOfDoctor.error = false;
            state.getPatientsOfDoctor.errorMessage = null;
            state.getPatientsOfDoctor.loading = false;
            state.getPatientsOfDoctor.success = true;
        },
        getAllPatientsOfDoctorFail(state, action) {
            state.getPatientsOfDoctor.data = null;
            state.getPatientsOfDoctor.errorMessage = action.payload;
            state.getPatientsOfDoctor.error = true;
            state.getPatientsOfDoctor.loading = false;
            state.getPatientsOfDoctor.success = false;
        },
        getAllPatientsOfDoctorReset(state) {
            state.getPatientsOfDoctor.data = null;
            state.getPatientsOfDoctor.error = false;
            state.getPatientsOfDoctor.errorMessage = null;
            state.getPatientsOfDoctor.loading = false;
            state.getPatientsOfDoctor.success = false;
        },
        getAllAppointmentsOfDoctorStart(state) {
            state.getAppointmentsOfDoctor.data = null;
            state.getAppointmentsOfDoctor.error = false;
            state.getAppointmentsOfDoctor.errorMessage = null;
            state.getAppointmentsOfDoctor.loading = true;
            state.getAppointmentsOfDoctor.success = false;
        },
        getAllAppointmentsOfDoctorSuccess(state, action) {
            state.getAppointmentsOfDoctor.data = action.payload;
            state.getAppointmentsOfDoctor.error = false;
            state.getAppointmentsOfDoctor.errorMessage = null;
            state.getAppointmentsOfDoctor.loading = false;
            state.getAppointmentsOfDoctor.success = true;
        },
        getAllAppointmentsOfDoctorFail(state, action) {
            state.getAppointmentsOfDoctor.data = null;
            state.getAppointmentsOfDoctor.errorMessage = action.payload;
            state.getAppointmentsOfDoctor.error = true;
            state.getAppointmentsOfDoctor.loading = false;
            state.getAppointmentsOfDoctor.success = false;
        },
        getAllAppointmentsOfDoctorReset(state) {
            state.getAppointmentsOfDoctor.data = null;
            state.getAppointmentsOfDoctor.error = false;
            state.getAppointmentsOfDoctor.errorMessage = null;
            state.getAppointmentsOfDoctor.loading = false;
            state.getAppointmentsOfDoctor.success = false;
        },
        getUpcommingAppointmentsStart(state) {
            state.getUpcommingAppointments.data = null;
            state.getUpcommingAppointments.error = false;
            state.getUpcommingAppointments.errorMessage = null;
            state.getUpcommingAppointments.loading = true;
            state.getUpcommingAppointments.success = false;
        },
        getUpcommingAppointmentsSuccess(state, action) {
            state.getUpcommingAppointments.data = action.payload;
            state.getUpcommingAppointments.error = false;
            state.getUpcommingAppointments.errorMessage = null;
            state.getUpcommingAppointments.loading = false;
            state.getUpcommingAppointments.success = true;
        },
        getUpcommingAppointmentsFail(state, action) {
            state.getUpcommingAppointments.data = null;
            state.getUpcommingAppointments.errorMessage = action.payload;
            state.getUpcommingAppointments.error = true;
            state.getUpcommingAppointments.loading = false;
            state.getUpcommingAppointments.success = false;
        },
        getUpcommingAppointmentsReset(state) {
            state.getUpcommingAppointments.data = null;
            state.getUpcommingAppointments.error = false;
            state.getUpcommingAppointments.errorMessage = null;
            state.getUpcommingAppointments.loading = false;
            state.getUpcommingAppointments.success = false;
        }
    }
});

export const {
    getAllTodayAppointmentsReset,
    getAllTodayAppointmentsStart,
    getAllTodayAppointmentsFail,
    getAllTodayAppointmentsSuccess,
    getAllTodayPatientsFail,
    getAllTodayPatientsReset,
    getAllTodayPatientsSuccess,
    getAllTodayPatientsStart,
    getAllPatientsByMonthFail,
    getAllPatientsByMonthReset,
    getAllPatientsByMonthStart,
    getAllPatientsByMonthSuccess,
    getAllPatientsByAgeFail,
    getAllPatientsByAgeReset,
    getAllPatientsByAgeStart,
    getAllPatientsByAgeSuccess,
    getAllPatientsOfDoctorFail,
    getAllPatientsOfDoctorStart,
    getAllPatientsOfDoctorReset,
    getAllPatientsOfDoctorSuccess,
    getAllAppointmentsOfDoctorFail,
    getAllAppointmentsOfDoctorReset,
    getAllAppointmentsOfDoctorStart,
    getAllAppointmentsOfDoctorSuccess,
    getUpcommingAppointmentsFail,
    getUpcommingAppointmentsReset,
    getUpcommingAppointmentsStart,
    getUpcommingAppointmentsSuccess
} = dashboardSlice.actions;

export const getTodayAppointmentsStatistics = (data) => async (dispatch) => {
    try {
        dispatch(getAllAppointmentsStart());
        const response = await APIClient.get('/statistics/appointments/count', { params: { day: data.today } });
        if (response && response.data && response.data.success) {
            dispatch(getAllTodayAppointmentsSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getAllTodayAppointmentsFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllTodayAppointmentsFail);
    }
};

export const getPatientsStatistics = (data) => async (dispatch) => {
    try {
        dispatch(getAllTodayPatientsStart());
        const response = await APIClient.get('/statistics/patients/registration', { params: { day: data.today } });
        if (response && response.data && response.data.success) {
            dispatch(getAllTodayPatientsSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getAllTodayPatientsFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllTodayPatientsFail);
    }
};

export const getAllPatientsByMonth = (data) => async (dispatch) => {
    try {
        dispatch(getAllPatientsByMonthStart());
        const response = await APIClient.get('/statistics/patients/registration/monthly', { params: { year: data.year } });
        if (response && response.data && response.data.success) {
            dispatch(getAllPatientsByMonthSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getAllPatientsByMonthFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllPatientsByMonthFail);
    }
};

export const getAllPatientsByAge = (data) => async (dispatch) => {
    try {
        dispatch(getAllPatientsByAgeStart());
        const response = await APIClient.get('/statistics/patients/age', { params: { year: data.year } });
        if (response && response.data && response.data.success) {
            dispatch(getAllPatientsByAgeSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getAllPatientsByAgeFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllPatientsByAgeFail);
    }
};

export const getAllPatientsOfDoctor = (data) => async (dispatch) => {
    try {
        dispatch(getAllPatientsOfDoctorStart());
        const response = await APIClient.get('/statistics/doctor/patients-count', { params: { date: data.date } });
        if (response && response.data && response.data.success) {
            dispatch(getAllPatientsOfDoctorSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getAllPatientsOfDoctorFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllPatientsOfDoctorFail);
    }
};
export const getAllAppointmentsOfDoctor = (data) => async (dispatch) => {
    try {
        dispatch(getAllAppointmentsOfDoctorStart());
        const response = await APIClient.get('/statistics/appointments/doctor', { params: { date: data.date } });
        if (response && response.data && response.data.success) {
            dispatch(getAllAppointmentsOfDoctorSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getAllAppointmentsOfDoctorFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllAppointmentsOfDoctorFail);
    }
};

export const getUpcommingAppointments = (data) => async (dispatch) => {
    try {
        dispatch(getUpcommingAppointmentsStart());
        const response = await APIClient.get('/statistics/appointments/upcoming', {
            params: { page: data.page, limit: data.limit, pagination: data.pagination, date: data.date }
        });
        if (response && response.data && response.data.success) {
            dispatch(getUpcommingAppointmentsSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getUpcommingAppointmentsFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getUpcommingAppointmentsFail);
    }
};

export default dashboardSlice.reducer;
