import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';
import APIClient from '../../services/ApiClient';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loginState: { ...initialState },
        forgotState: { ...initialState },
        resetState: { ...initialState }
    },
    reducers: {
        loginStart(state) {
            state.loginState.data = null;
            state.loginState.error = false;
            state.loginState.errorMessage = null;
            state.loginState.loading = true;
            state.loginState.success = false;
        },
        loginSuccess(state, action) {
            state.loginState.data = action.payload;
            state.loginState.error = false;
            state.loginState.errorMessage = null;
            state.loginState.loading = false;
            state.loginState.success = true;
        },
        loginFail(state, action) {
            state.loginState.data = null;
            state.loginState.errorMessage = action.payload;
            state.loginState.error = true;
            state.loginState.loading = false;
            state.loginState.success = false;
        },
        loginReset(state) {
            state.loginState.data = null;
            state.loginState.error = false;
            state.loginState.errorMessage = null;
            state.loginState.loading = false;
            state.loginState.success = false;
        },
        forgotStart(state) {
            state.forgotState.data = null;
            state.forgotState.error = false;
            state.forgotState.errorMessage = null;
            state.forgotState.loading = true;
            state.forgotState.success = false;
        },
        forgotSuccess(state, action) {
            state.forgotState.data = action.payload;
            state.forgotState.error = false;
            state.forgotState.errorMessage = null;
            state.forgotState.loading = false;
            state.forgotState.success = true;
        },
        forgotFail(state, action) {
            state.forgotState.data = null;
            state.forgotState.errorMessage = action.payload;
            state.forgotState.error = true;
            state.forgotState.loading = false;
            state.forgotState.success = false;
        },
        forgotReset(state) {
            state.forgotState.data = null;
            state.forgotState.error = false;
            state.forgotState.errorMessage = null;
            state.forgotState.loading = false;
            state.forgotState.success = false;
        },
        resetStart(state) {
            state.resetState.data = null;
            state.resetState.error = false;
            state.resetState.errorMessage = null;
            state.resetState.loading = true;
            state.resetState.success = false;
        },
        resetSuccess(state, action) {
            state.resetState.data = action.payload;
            state.resetState.error = false;
            state.resetState.errorMessage = null;
            state.resetState.loading = false;
            state.resetState.success = true;
        },
        resetFail(state, action) {
            state.resetState.data = null;
            state.resetState.errorMessage = action.payload;
            state.resetState.error = true;
            state.resetState.loading = false;
            state.resetState.success = false;
        },
        resetPasswordReset(state) {
            state.resetState.data = null;
            state.resetState.error = false;
            state.resetState.errorMessage = null;
            state.resetState.loading = false;
            state.resetState.success = false;
        }
    }
});

export const {
    loginRequest,
    loginStart,
    loginSuccess,
    loginFail,
    loginReset,
    forgotFail,
    forgotReset,
    forgotStart,
    forgotSuccess,
    resetFail,
    resetStart,
    resetSuccess,
    resetPasswordReset
} = authSlice.actions;

export default authSlice.reducer;

export const login = (payload, options) => async (dispatch) => {
    try {
        dispatch(loginStart());
        const { navigate, pathname } = options;
        const { email, password } = payload;
        const response = await APIClient.post('/auth/login', {
            email: email.toLowerCase(),
            password
        });
        if (response && response.data && response.data.success) {
            const accessToken = response.data.data.accessToken;
            const decodedToken = jwtDecode(accessToken);
            const expiresIn = new Date(decodedToken.exp * 1000).toISOString();
            dispatch(loginSuccess(accessToken));
            localStorage.setItem('patient-menagment-token', accessToken);
            localStorage.setItem('expiresIn', expiresIn);
            if (decodedToken.role === 'SUPERADMIN') {
                navigate('/clinics');
            } else {
                navigate(pathname);
            }
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, loginFail);
    }
};

export const forgotPassword = (payload, options) => async (dispatch) => {
    try {
        dispatch(forgotStart());
        const { navigate, pathname } = options;
        const response = await APIClient.post('/auth/forgot-password', payload);
        if (response && response.data && response.data.success) {
            dispatch(forgotSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The email has been sent succesfully!' }));
            navigate(pathname);
        } else if (response && response.data && response.data.errors) {
            dispatch(forgotFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, forgotFail);
    }
};

export const resetPassword = (payload, options) => async (dispatch) => {
    try {
        dispatch(resetStart());
        const { navigate, pathname } = options;
        const { token, newPassword, passwordConfirm, email } = payload;
        const response = await APIClient.post(`/auth/reset-password/${token}`, { newPassword, passwordConfirm, email });
        if (response && response.data && response.data.success) {
            dispatch(resetSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The password changed succesfully!' }));
            navigate(pathname);
        } else if (response && response.data && response.data.errors) {
            dispatch(resetFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, resetFail);
    }
};

export const resetForgot = () => async (dispatch) => {
    dispatch(forgotReset());
};

export const resetResetForgot = () => async (dispatch) => {
    dispatch(resetResetForgot());
};
