import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import jwtDecode from 'jwt-decode';
import DoctorRoutes from './DoctorRoutes';
import AdminRoutes from './AdminRoutes';
import SuperAdminRoutes from './SuperAdminRoutes';

export default function ThemeRoutes() {
    const token = localStorage.getItem('patient-menagment-token');
    let decoded;
    let role;

    if (token) {
        decoded = jwtDecode(token);
        role = decoded.role;
    }

    const routes = () => {
        if (role === 'SUPERADMIN') {
            return SuperAdminRoutes;
        }
        if (role === 'ADMIN') {
            return AdminRoutes;
        }
        if (role === 'DOCTOR') {
            return DoctorRoutes;
        }
        return LoginRoutes;
    };

    return useRoutes([LoginRoutes, AuthenticationRoutes, routes()]);
}
