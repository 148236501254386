import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const PatientsPage = Loadable(lazy(() => import('views/patients')));
const PatientsProfilePage = Loadable(lazy(() => import('views/patients/PatientProfile')));
const CalendarPage = Loadable(lazy(() => import('views/calendar')));
const AppointmentPage = Loadable(lazy(() => import('views/appointments')));
const AddPatientPage = Loadable(lazy(() => import('views/patients/AddPatient')));
const DoctorsPage = Loadable(lazy(() => import('views/doctors')));
const DoctorProfilePage = Loadable(lazy(() => import('views/doctors/DoctorProfile')));
const EditPatientPage = Loadable(lazy(() => import('views/patients/EditPatient')));
const AppointmentsDetailsPage = Loadable(lazy(() => import('views/appointments/AppointmentsDetails')));
const AddAppointmentPage = Loadable(lazy(() => import('views/appointments/AddAppointment')));
const AddDoctorPage = Loadable(lazy(() => import('views/doctors/AddDoctor')));
const EditDoctorPage = Loadable(lazy(() => import('views/doctors/EditDoctor')));
const EditAppointmentPage = Loadable(lazy(() => import('views/appointments/EditAppointment')));
const EditSpecializationPage = Loadable(lazy(() => import('views/specializations/EditSpecialization')));
const AddSpecializationPage = Loadable(lazy(() => import('views/specializations/AddSpecialization')));
const SpecializationProfilePage = Loadable(lazy(() => import('views/specializations/SpecializationProfile')));
const SpecializationPage = Loadable(lazy(() => import('views/specializations')));
const MyProfile = Loadable(lazy(() => import('views/me/Profile')));

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/patients',
            element: <PatientsPage />
        },
        {
            path: '/patient/profile/:id',
            element: <PatientsProfilePage />
        },
        {
            path: '/calendar',
            element: <CalendarPage />
        },
        {
            path: '/appointments',
            element: <AppointmentPage />
        },
        {
            path: '/appointment/profile/:id',
            element: <AppointmentsDetailsPage />
        },
        {
            path: '/appointment/create',
            element: <AddAppointmentPage />
        },
        {
            path: '/appointment/edit/:id',
            element: <EditAppointmentPage />
        },
        {
            path: '/patient/create',
            element: <AddPatientPage />
        },
        {
            path: '/patient/edit/:id',
            element: <EditPatientPage />
        },
        {
            path: '/doctors',
            element: <DoctorsPage />
        },
        {
            path: '/doctor/profile/:id',
            element: <DoctorProfilePage />
        },
        {
            path: '/doctor/create',
            element: <AddDoctorPage />
        },
        {
            path: '/doctor/edit/:id',
            element: <EditDoctorPage />
        },
        {
            path: '/specializations',
            element: <SpecializationPage />
        },
        {
            path: '/specialization/profile/:id',
            element: <SpecializationProfilePage />
        },
        {
            path: '/specialization/create',
            element: <AddSpecializationPage />
        },
        {
            path: '/specialization/edit/:id',
            element: <EditSpecializationPage />
        },
        {
            path: '/profile/:id',
            element: <MyProfile />
        }
    ]
};

export default AdminRoutes;
