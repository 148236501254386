import { createSlice } from '@reduxjs/toolkit';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const clinicSlice = createSlice({
    name: 'clinics',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState },
        uploadTemplate: { ...initialState },
        uploadDefaultTemplate: { ...initialState },
        getDefaultTemplate: { ...initialState }
    },
    reducers: {
        createClinicStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createClinicSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createClinicFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createClinicReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllClinicsStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllClinicsSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllClinicsFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllClinicsReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneClinicStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneClinicSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneClinicFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneClinicReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updateClinicStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateClinicSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateClinicFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateClinicReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteClinicStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteClinicSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteClinicFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        },
        uploadTemplateStart(state) {
            state.uploadTemplate.data = null;
            state.uploadTemplate.error = false;
            state.uploadTemplate.errorMessage = null;
            state.uploadTemplate.loading = true;
            state.uploadTemplate.success = false;
        },
        uploadTemplateSuccess(state, action) {
            state.uploadTemplate.data = action.payload;
            state.uploadTemplate.error = false;
            state.uploadTemplate.errorMessage = null;
            state.uploadTemplate.loading = false;
            state.uploadTemplate.success = true;
        },
        uploadTemplateFail(state, action) {
            state.uploadTemplate.data = null;
            state.uploadTemplate.errorMessage = action.payload;
            state.uploadTemplate.error = true;
            state.uploadTemplate.loading = false;
            state.uploadTemplate.success = false;
        },
        uploadTemplateReset(state) {
            state.uploadTemplate.data = null;
            state.uploadTemplate.error = false;
            state.uploadTemplate.errorMessage = null;
            state.uploadTemplate.loading = false;
            state.uploadTemplate.success = false;
        },
        uploadDefaultTemplateStart(state) {
            state.uploadDefaultTemplate.data = null;
            state.uploadDefaultTemplate.error = false;
            state.uploadDefaultTemplate.errorMessage = null;
            state.uploadDefaultTemplate.loading = true;
            state.uploadDefaultTemplate.success = false;
        },
        uploadDefaultTemplateSuccess(state, action) {
            state.uploadDefaultTemplate.data = action.payload;
            state.uploadDefaultTemplate.error = false;
            state.uploadDefaultTemplate.errorMessage = null;
            state.uploadDefaultTemplate.loading = false;
            state.uploadDefaultTemplate.success = true;
        },
        uploadDefaultTemplateFail(state, action) {
            state.uploadDefaultTemplate.data = null;
            state.uploadDefaultTemplate.errorMessage = action.payload;
            state.uploadDefaultTemplate.error = true;
            state.uploadDefaultTemplate.loading = false;
            state.uploadDefaultTemplate.success = false;
        },
        uploadDefaultTemplateReset(state) {
            state.uploadDefaultTemplate.data = null;
            state.uploadDefaultTemplate.error = false;
            state.uploadDefaultTemplate.errorMessage = null;
            state.uploadDefaultTemplate.loading = false;
            state.uploadDefaultTemplate.success = false;
        },
        getDefaultTemplateStart(state) {
            state.getDefaultTemplate.data = null;
            state.getDefaultTemplate.error = false;
            state.getDefaultTemplate.errorMessage = null;
            state.getDefaultTemplate.loading = true;
            state.getDefaultTemplate.success = false;
        },
        getDefaultTemplateSuccess(state, action) {
            state.getDefaultTemplate.data = action.payload;
            state.getDefaultTemplate.error = false;
            state.getDefaultTemplate.errorMessage = null;
            state.getDefaultTemplate.loading = false;
            state.getDefaultTemplate.success = true;
        },
        getDefaultTemplateFail(state, action) {
            state.getDefaultTemplate.data = null;
            state.getDefaultTemplate.errorMessage = action.payload;
            state.getDefaultTemplate.error = true;
            state.getDefaultTemplate.loading = false;
            state.getDefaultTemplate.success = false;
        },
        getDefaultTemplateReset(state) {
            state.getDefaultTemplate.data = null;
            state.getDefaultTemplate.error = false;
            state.getDefaultTemplate.errorMessage = null;
            state.getDefaultTemplate.loading = false;
            state.getDefaultTemplate.success = false;
        }
    }
});

export const {
    createClinicStart,
    createClinicSuccess,
    createClinicFail,
    createClinicReset,
    getAllClinicsStart,
    getAllClinicsSuccess,
    getAllClinicsFail,
    getAllClinicsReset,
    updateClinicFail,
    updateClinicReset,
    updateClinicSuccess,
    updateClinicStart,
    deleteClinicSuccess,
    deleteClinicFail,
    deleteClinicStart,
    getOneClinicFail,
    getOneClinicStart,
    getOneClinicReset,
    getOneClinicSuccess,
    uploadTemplateFail,
    uploadTemplateReset,
    uploadTemplateStart,
    uploadTemplateSuccess,
    uploadDefaultTemplateSuccess,
    uploadDefaultTemplateFail,
    uploadDefaultTemplateReset,
    uploadDefaultTemplateStart,
    getDefaultTemplateFail,
    getDefaultTemplateReset,
    getDefaultTemplateSuccess,
    getDefaultTemplateStart
} = clinicSlice.actions;

export const uploadTemplate = (id, clinicTemplate, recall, navigate, pathname) => async (dispatch) => {
    try {
        const response = await APIClient.post(`/clinics/template/upload/${id}`, clinicTemplate);
        if (response && response.data && response.data.success) {
            dispatch(uploadTemplateSuccess(response.data));
            if (recall) recall();
            // dispatch(addNotification({ severity: 'success', message: 'The clinic created successfully!' }));
            navigate(pathname);
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, uploadTemplateFail);
    }
};
// export const uploadDefaultTemplate = (clinicTemplate) => async (dispatch) => {
//     try {
//         const response = await APIClient.post(`/clinics/defaultTemplate/upload`, clinicTemplate);
//         if (response && response.data && response.data.success) {
//             dispatch(uploadDefaultTemplateSuccess(response.data));
//             dispatch(addNotification({ severity: 'success', message: 'The default template uploaded successfully!' }));
//         } else {
//             dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
//         }
//     } catch (error) {
//         HandleAPIError(error, dispatch, uploadDefaultTemplateFail);
//     }
// };

export const uploadDefaultTemplate = (clinicTemplate) => async (dispatch) => {
    try {
        const response = await APIClient.post(`/clinics/defaultTemplate/upload`, clinicTemplate);
        if (response && response.data && response.data.success) {
            dispatch(uploadDefaultTemplateSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The default template uploaded successfully!' }));
            return response.data.data;
        }
        dispatch(uploadDefaultTemplateFail('Failed to upload gile!'));
        dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        return null;
    } catch (error) {
        HandleAPIError(error, dispatch, uploadDefaultTemplateFail);
        return error;
    }
};

export const getDefaultTemplate = () => async (dispatch) => {
    try {
        const response = await APIClient.get(`/files/template/default`);
        if (response && response.data && response.data.success) {
            dispatch(getDefaultTemplateSuccess(response.data));
        } else {
            dispatch(getDefaultTemplateFail(response.data));
        }
    } catch (error) {
        if (error?.response?.data?.type === 'General_Error') {
            const errorMessage = error.response.data.message;
            dispatch(getDefaultTemplateFail(errorMessage));
        }
    }
};

export const createClinic = (payload, options) => async (dispatch) => {
    try {
        const { clinicData, clinicTemplate } = payload;
        const { recall, navigate, pathname } = options;
        dispatch(createClinicStart());
        const response = await APIClient.post('/clinics/create', clinicData);
        if (response && response.data && response.data.success) {
            dispatch(createClinicSuccess(response.data));
            if (clinicTemplate) {
                dispatch(uploadTemplate(response.data.data.id, clinicTemplate, recall, navigate, pathname));
                dispatch(addNotification({ severity: 'success', message: 'The clinic created successfully!' }));
            } else {
                if (recall) recall();
                navigate(pathname);
                dispatch(addNotification({ severity: 'success', message: 'The clinic created successfully!' }));
            }
        } else {
            await APIClient.delete(`/clinics/${response.data.data.id}`);
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createClinicFail);
    }
};

export const getAllClinics = (data) => async (dispatch) => {
    try {
        dispatch(getAllClinicsStart());
        const response = await APIClient.get('/clinics', { params: { limit: data.limit, page: data.page, search: data.search } });
        if (response && response.data && response.data.success) {
            dispatch(getAllClinicsSuccess(response.data));
        } else {
            dispatch(getAllClinicsFail('Failed to get all clinics!'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllClinicsFail);
    }
};

export const getOneClinic = (clinicId) => async (dispatch) => {
    try {
        dispatch(getOneClinicStart());
        const response = await APIClient.get(`/clinics/${clinicId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneClinicSuccess(response.data));
        } else {
            dispatch(getOneClinicFail('Failed to get one clinic!'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneClinicFail);
    }
};

export const updateClinic = ({ clinicId, clinicData, recall, navigate, pathname, clinicTemplate }) => async (dispatch) => {
    try {
        dispatch(updateClinicStart());
        const response = await APIClient.patch(`/clinics/${clinicId}`, clinicData);
        if (response && response.data) {
            dispatch(updateClinicSuccess(response.data));
            if (clinicTemplate) {
                dispatch(uploadTemplate(response.data.data.id, clinicTemplate, recall, navigate, pathname));
                dispatch(addNotification({ severity: 'success', message: 'The clinic updated successfully!' }));
            } else {
                dispatch(addNotification({ severity: 'success', message: 'The clinic updated successfully!' }));
                if (recall) recall();
                navigate(pathname);
            }
        } else {
            dispatch(updateClinicFail('Failed to update clinic!'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateClinicFail);
    }
};

export const deleteClinic = (clinicId, recall) => async (dispatch) => {
    try {
        dispatch(deleteClinicStart());
        const response = await APIClient.patch(`/clinics/${clinicId}`, { isActive: false });
        if (response.data && response.data && response.data.success) {
            dispatch(deleteClinicSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The clinic deleted successfully!' }));
            if (recall) recall();
        } else {
            dispatch(deleteClinicFail('Failed to delete clinic!'));
            dispatch(addNotification({ severity: 'error', message: 'Failed to delete the clinic!' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteClinicFail);
    }
};

export default clinicSlice.reducer;
