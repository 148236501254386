import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the default calendar styles
import { Typography, Box, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import moment from 'moment';
import nofiles from '../../assets/images/noFiles.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAppointments } from 'store/calendar';
import { getMe } from 'store/me';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
    calendarContainer: {
        color: theme.palette.grey[400],
        fontWeight: 100
    },
    calendar: {
        border: 'none',
        borderRadius: 5,
        padding: 5,
        fontFamily: theme.typography.h6,
        '&.react-calendar': {
            width: '100%',
            padding: 10
        },
        '&.react-calendar__month-view__weekdays__weekday': {
            fontWeight: '100 !important',
            color: 'red'
        },

        '& .react-calendar__tile--active': {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 5,
            color: 'white'
        },

        '& .react-calendar__month-view__days__day--weekend': {
            color: theme.palette.secondary.main
        },
        '& .react-calendar__month-view__days__day--weekend--active': {
            color: theme.palette.common.white
        },
        '& .react-calendar__tile': {
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
                borderRadius: 5
            }
        },
        '& .react-calendar__tile--active:enabled:hover, & .react-calendar__tile--active:enabled:focus': {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 5,
            color: theme.palette.common.white
        },
        '& .react-calendar__tile--now': {
            backgroundColor: theme.palette.secondary.light + 50,
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            borderRadius: 5
        }
    }
}));

function MyCalendar() {
    const classes = useStyles();
    const defaultPage = 0;
    const defaultLimit = 5;
    const [page, setPage] = useState(defaultPage);
    const [limit, setLimit] = useState(defaultLimit);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const theme = useTheme();
    const [selectedAppointments, setSelectedAppointments] = useState([]);
    const [currentUserData, setCurrentUserData] = useState({});

    const appointmentsResponse = useSelector((state) => state.calendar.getAll);

    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.me.getMe);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (currentUser) {
            switch (true) {
                case currentUser.loading:
                    setLoading(true);
                    break;
                case currentUser.success:
                    setCurrentUserData(currentUser.data);
                    setLoading(false);
                    break;
                case currentUser.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUserData?.doctor?.id) {
            dispatch(
                getAllAppointments({ pagination: false, doctorId: currentUserData?.doctor?.id, day: dayjs(date).format('YYYY-MM-DD') })
            );
        }
    }, [dispatch, currentUserData?.doctor?.id, date]);

    useEffect(() => {
        if (appointmentsResponse) {
            switch (true) {
                case appointmentsResponse.loading:
                    setLoading(true);
                    break;
                case appointmentsResponse.success: {
                    const modifiedData = appointmentsResponse?.data?.data?.docs?.map((appointment) => ({
                        id: appointment?.id,
                        name: appointment?.name,
                        doctor: appointment?.doctor.user.firstName,
                        number: appointment?.number,
                        date: moment(appointment?.startTime).format('DD/MM/YYYY'),
                        description: appointment?.description ? appointment?.description : 'None',
                        time: `${moment(appointment?.startTime).format('HH:mm A')} - ${moment(appointment?.endTime).format('HH:mm A')}`,
                        status: appointment?.status
                    }));
                    setSelectedAppointments(modifiedData);
                    setTotalCount(appointmentsResponse?.data?.data?.meta?.total);
                    setLoading(false);
                    break;
                }
                case appointmentsResponse.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [appointmentsResponse]);

    const onChange = (selectedDate) => {
        setDate(selectedDate);
    };

    const timelineItemStyles = {
        marginBottom: '20px'
    };

    const contentStyles = {
        backgroundColor: theme.palette.grey[50],
        borderRadius: '8px',
        width: '100%',
        marginLeft: '15px'
    };

    return (
        <Grid
            container
            sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: 3,
                boxShadow: theme.customShadows.z1
            }}
        >
            <Typography variant="subtitle1" pt={2} pl={2}>
                My Schedule
            </Typography>
            <Grid item xs={12}>
                <Box className={classes.calendarContainer}>
                    <Calendar onChange={onChange} value={date} className={classes.calendar} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Timeline sx={{ mt: 2, height: 'auto' }}>
                    {selectedAppointments.map((appointment, index) => (
                        <TimelineItem
                            key={index}
                            style={timelineItemStyles}
                            sx={{
                                '&::before': {
                                    padding: 0
                                }
                            }}
                        >
                            <TimelineSeparator>
                                <Box display="flex" alignItems="center">
                                    <TimelineDot sx={{ backgroundColor: theme.palette.secondary.main }} />
                                    <Typography variant="body2" marginLeft="10px">
                                        {appointment.time.split(' - ')[0]}
                                    </Typography>
                                </Box>
                                {index !== totalCount.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <Grid lg={12} container item>
                                <TimelineContent style={contentStyles}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ backgroundColor: theme.palette.grey[50] }}
                                    >
                                        <Box>
                                            <Typography variant="subtitle1">{appointment.type}</Typography>
                                            <Typography variant="h6" sx={{ color: theme.palette.grey[500] }}>
                                                {appointment.time}
                                            </Typography>
                                            <Typography variant="subtitle1" mt={1}>
                                                {appointment.name}
                                            </Typography>
                                        </Box>

                                        <Grid>
                                            <Box>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        padding: '7px',
                                                        fontSize: '10px',
                                                        borderRadius: 2,
                                                        color: theme.palette.grey[800]
                                                    }}
                                                >
                                                    {appointment.status}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        padding: '7px',
                                                        fontSize: '10px',
                                                        borderRadius: 2,
                                                        color: theme.palette.grey[500],
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {appointment.date}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TimelineContent>
                            </Grid>
                        </TimelineItem>
                    ))}

                    {selectedAppointments.length === 0 && (
                        <Grid container justifyContent="center" mt={3} pb={4} sx={{ height: 250 }}>
                            <Grid container justifyContent="center">
                                <img src={nofiles} alt="nofiles" width="30%" />
                            </Grid>
                            <Typography mt={2} variant="h6" sx={{ color: theme.palette.grey[500], width: 200, textAlign: 'center' }}>
                                {' '}
                                No appointments scheduled for this date!
                            </Typography>
                        </Grid>
                    )}
                </Timeline>
            </Grid>
        </Grid>
    );
}

export default MyCalendar;
