import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
import { IconLogout, IconSettings } from '@tabler/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ProfileImg from '../../../../assets/images/profile-img.jpg';
import { clearMe, getMe } from 'store/me';

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [currentUserData, setCurrentUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.me.getMe);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (currentUser) {
            switch (true) {
                case currentUser.loading:
                    setLoading(true);
                    break;
                case currentUser.success:
                    setCurrentUserData(currentUser.data);
                    setLoading(false);
                    break;
                case currentUser.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [currentUser]);

    const handleLogout = async () => {
        dispatch(clearMe());
        localStorage.clear();
        navigate('/');
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
            setSelectedIndex(-1);
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '4px',
                    mr: 1,

                    borderColor: 'transparent',
                    backgroundColor: 'transparent',

                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        backgroundColor: 'none'
                    },
                    '&.MuiButtonBase-root.MuiChip-root:active': {
                        boxShadow: 'none'
                    },

                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    !loading ? (
                        currentUser?.data?.profilePic ? (
                            <Avatar
                                src={ProfileImg}
                                sx={{
                                    margin: '8px 0 8px 8px !important',
                                    cursor: 'pointer'
                                }}
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                color="inherit"
                            />
                        ) : (
                            <Grid
                                sx={{
                                    backgroundColor: theme.palette.grey[200],
                                    p: '12px',
                                    borderRadius: '50%'
                                }}
                            >
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: theme.palette.grey[500] }}>
                                    {`${currentUserData?.firstName
                                        ?.toUpperCase()
                                        .charAt(0)}${currentUserData?.lastName?.toUpperCase().charAt(0)} `}
                                </Typography>
                            </Grid>
                        )
                    ) : (
                        <Skeleton
                            variant="circular"
                            width={40}
                            height={40}
                            sx={{
                                borderRadius: '50%'
                            }}
                        />
                    )
                }
                label={
                    !loading ? (
                        <Grid container alignItems="center">
                            {!matchDownXs && (
                                <Grid mr={1}>
                                    <Typography m={0} variant="subtitle1" sx={{ color: theme.palette.grey[800], ml: 1 }}>
                                        {currentUser?.role === 'DOCTOR' ? `Dr.${currentUserData?.firstName}` : currentUserData?.firstName}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: '10px', color: theme.palette.grey[400], lineHeight: '10px', ml: 1 }}
                                    >
                                        {currentUserData?.role?.toLowerCase()}
                                    </Typography>{' '}
                                </Grid>
                            )}
                            <KeyboardArrowDownIcon stroke={1.5} size="1.5rem" sx={{ color: theme.palette.grey[800] }} />
                        </Grid>
                    ) : (
                        <Grid>
                            {' '}
                            <Skeleton variant="text" width={70} height={10} animation="wave" />
                            <Skeleton variant="text" width={50} height={10} animation="wave" />
                        </Grid>
                    )
                }
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ pt: 2, pl: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                {currentUser?.data?.profilePic ? (
                                                    <Avatar
                                                        src={ProfileImg}
                                                        sx={{
                                                            margin: '8px 0 8px 8px !important',
                                                            cursor: 'pointer'
                                                        }}
                                                        ref={anchorRef}
                                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    />
                                                ) : (
                                                    <Grid sx={{ backgroundColor: theme.palette.grey[200], p: '12px', borderRadius: '50%' }}>
                                                        <Typography
                                                            sx={{ fontSize: '14px', fontWeight: '500', color: theme.palette.grey[500] }}
                                                        >
                                                            {`${currentUser?.data?.firstName?.charAt(
                                                                0
                                                            )}${currentUser?.data?.lastName?.charAt(0)} `}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                <Grid container flexDirection="column" pl={1}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.grey[800], fontWeight: 400 }}
                                                    >
                                                        Welcome,{' '}
                                                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                            {`${currentUser?.data?.firstName}!`}
                                                        </Typography>
                                                    </Typography>

                                                    <Typography variant="subtitle2"> {currentUser?.data?.role.toLowerCase()}</Typography>
                                                </Grid>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, `/profile/${currentUser.data.id}`)}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
