import { useTheme } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';

const DoctorAnalyticsCard = ({ title, number, description, date, percentage, icon, percentageBackgroundColor, textColor }) => {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid
            sx={{
                backgroundColor: theme.palette.common.white,
                p: 2.5,
                m: 1,
                borderRadius: 3,
                boxShadow: theme.customShadows.z1
            }}
        >
            <Grid>
                <Grid>
                    <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid container flexDirection="row" alignItems="center">
                    <Grid item>
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.grey[400]
                            }}
                        >
                            {description}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ ml: 1, backgroundColor: percentageBackgroundColor, color: textColor, p: 0.4, borderRadius: 1 }}>
                        {percentage}
                    </Grid>
                </Grid>

                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" pt={2}>
                    <Typography variant="h3" sx={{ color: theme.palette.secondary.main }}>
                        {number}
                    </Typography>
                    <Grid>{icon}</Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DoctorAnalyticsCard;
