import { addNotification } from 'store/notification';

const HandleAPIError = (error, dispatch, action) => {
    if (error && error?.response && error?.response?.data) {
        if (error?.response?.data?.type === 'General_Error') {
            const errorMessage = error.response.data.message;
            dispatch(action(errorMessage));
            dispatch(addNotification({ severity: 'error', message: errorMessage }));
        } else if (error.response.data.type === 'Nest_Error') {
            const errorMessage = Object.values(error?.response?.data?.errors)[0];
            dispatch(action(errorMessage));
            dispatch(addNotification({ severity: 'error', message: errorMessage }));
        } else {
            dispatch(action('Internal Server Error'));
            dispatch(addNotification({ severity: 'error', message: 'Internal Server Error' }));
        }
    }
};

export default HandleAPIError;
