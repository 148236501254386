import { createSlice } from '@reduxjs/toolkit';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const userSlice = createSlice({
    name: 'users',
    initialState: {
        getAll: { ...initialState },
        pendingValue: 0,
        all: 0,
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        createUserStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createUserSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createUserFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createUserReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllUsersStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllUsersSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllUsersFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllUsersReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneUserStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneUserSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneUserFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneUserReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updateUserStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateUserSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateUserFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateUserReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteUserStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteUserSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteUserFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    createUserStart,
    createUserSuccess,
    createUserFail,
    createUserReset,
    getAllUsersStart,
    getAllUsersSuccess,
    getAllUsersFail,
    getAllUsersReset,
    updateUserFail,
    updateUserReset,
    updateUserSuccess,
    updateUserStart,
    deleteUserSuccess,
    deleteUserFail,
    deleteUserStart,
    getOneUserFail,
    getOneUserStart,
    getOneUserReset,
    getOneUserSuccess
} = userSlice.actions;

export const createUser = ({ userData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(createUserStart());
        const response = await APIClient.post('/admins/create', userData);
        if (response && response.data && response.data.success) {
            dispatch(createUserSuccess(response.data));
            if (recall) recall();
            navigate(pathname);
            dispatch(addNotification({ severity: 'success', message: 'The user created successfully!' }));
        } else {
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server!' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createUserFail);
    }
};

export const getAllUsers = (data) => async (dispatch) => {
    try {
        dispatch(getAllUsersStart());
        const response = await APIClient.get('/admins', { params: { limit: data.limit, page: data.page, search: data.search } });
        if (response && response.data && response.data.success) {
            dispatch(getAllUsersSuccess(response.data));
        } else {
            dispatch(getAllUsersFail(response.data));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllUsersFail);
    }
};

export const getOneUser = (userId) => async (dispatch) => {
    try {
        dispatch(getOneUserStart());
        const response = await APIClient.get(`/admins/${userId}`);
        if (response && response.data) {
            dispatch(getOneUserSuccess(response.data));
        } else {
            dispatch(getOneUserFail(response.data));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneUserFail);
    }
};

export const clearOneUser = () => async (dispatch) => {
    dispatch(getOneUserReset());
};

export const updateUser = ({ userId, userData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updateUserStart());
        const response = await APIClient.patch(`/admins/${userId}`, userData);
        if (response.data) {
            dispatch(updateUserSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The user updated successfully!' }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(updateUserFail('Failed to update user'));
            dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateUserFail);
    }
};

export const deleteUser = (userId, recall) => async (dispatch) => {
    try {
        dispatch(deleteUserStart());
        const response = await APIClient.patch(`/admins/${userId}`, { isActive: false });
        if (response.data && response.data.data) {
            dispatch(deleteUserSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: 'The user deleted successfully!' }));
            if (recall) recall();
        } else {
            dispatch(deleteUserFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: 'Failed to delete the user!' }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteUserFail);
    }
};

export default userSlice.reducer;
